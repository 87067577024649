import { render, staticRenderFns } from "./ClientUserList.vue?vue&type=template&id=38f81606&scoped=true&"
import script from "./ClientUserList.vue?vue&type=script&lang=js&"
export * from "./ClientUserList.vue?vue&type=script&lang=js&"
import style0 from "./ClientUserList.vue?vue&type=style&index=0&id=38f81606&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38f81606",
  null
  
)

export default component.exports