<template>
    <div class="eco-content-scroll">
        <div class="eco-content-space mh100">
            <div class="billing">
                <div class="invoices">
                    <div class="table-top">
                        {{$ml.get('BILLING_HISTORY')}}
                    </div>
                    <div class="table-list-wrapper">
                        <table class="table-list">
                            <thead class="font-bebas-neue">
                            <tr>
                                <th>{{$ml.get('INVOICE_N')}}</th>
                                <th>{{$ml.get('ISSUED_ON')}}</th>
                                <th>{{$ml.get('FILE')}}</th>
                                <th>{{$ml.get('NET_AMOUNT')}}</th>
                                <th>{{$ml.get('GROSS_AMOUNT')}}</th>
                                <th>{{$ml.get('STATUS')}}</th>
                                <th class="actions">{{$ml.get('ACTIONS')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="invoice in invoices" :key="invoice.id">
                                <td>
                                    <div>{{invoice.attributes.number}}</div>
                                </td>
                                <td>
                                    <div>{{invoice.attributes.issuedOn | dateFormat}}</div>
                                </td>
                                <td>
                                    <div>
                                        <a :href="invoice.attributes.attachment._url"
                                           :download="invoice.attributes.attachment._name"
                                           target="_blank">
                                            {{invoice.attributes.attachment._name | trimFilename}}
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div>{{ {amount: invoice.attributes.netAmount, currency: invoice.attributes.currency } | currency }}</div>
                                </td>
                                <td>
                                    <div>{{ {amount: invoice.attributes.amount, currency: invoice.attributes.currency } | currency }}</div>
                                </td>

                                <td>
                                    <b-badge v-if="invoice.attributes.paidOn" class="text-secondary">{{$ml.get('STATUS_PAID')}}</b-badge>
                                    <div class="trial-subscript text-secondary">
                                        <span v-if="invoice.attributes.paidOn" class="text-white" variant="secondary">{{$ml.get(`Paid_on`)}}: {{invoice.attributes.paidOn | dateFormat}}</span>
                                    </div>
                                    <b-badge v-if="!invoice.attributes.paidOn" class="text-white" variant="danger">{{$ml.get('STATUS_OPEN')}}</b-badge>
                                </td>

                                <td>
                                    <div class="table-action-btn-gorup text-center">
                                        <button-field @click="payInvoice(invoice)"
                                                      v-if="invoice.attributes.revolutLink"
                                                      circle
                                                      outline
                                                      icon="credit-card"
                                                      class="table-action-btn"
                                                      :disabled="invoice.attributes.paidOn"
                                                      v-tooltip="'Pay online'"
                                                      color="secondary"></button-field>

                                        <button-field @click="openInvoice(invoice)"
                                                      circle
                                                      outline
                                                      icon="download"
                                                      class="table-action-btn"
                                                      v-tooltip="'Download invoice'"
                                                      color="dark"></button-field>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="company-billing-info" v-if="company!==null">
                    <div class="table-top d-flex justify-content-between">
                        <span>{{$ml.get('COMPANY_BILLING_INFORMATION')}}</span>
                        <button-field @click="toggleModal(company)"
                                      circle
                                      outline
                                      icon="edit"
                                      class="table-action-btn"
                                      v-tooltip="'Edit information'"
                                      color="primary"></button-field>
                    </div>
                    <div class="company-data">
                        <div><label class="font-bebas-neue">{{$ml.get('COMPANY_NAME')}}</label><span>{{company.attributes.name}}</span>
                        </div>
                        <div><label class="font-bebas-neue">{{$ml.get('VAT_NUMBER')}}</label><span>{{company.attributes.vatNumber}}</span>
                        </div>
                        <div><label class="font-bebas-neue">{{$ml.get('BILLING_ADDRESS')}}</label><span>{{company.attributes.billingAddress}}</span>
                        </div>
                        <div><label
                                class="font-bebas-neue">{{$ml.get('RESPONSIBLE')}}</label><span>{{company.attributes.responsible}}</span>
                        </div>
                        <div><label
                                class="font-bebas-neue">{{$ml.get('EMAIL')}}</label><span>{{company.attributes.billingEmail}}</span>
                        </div>
                        <div><label
                                class="font-bebas-neue">{{$ml.get('TELEPHONE')}}</label><span>{{company.attributes.telephone}}</span>
                        </div>
                    </div>

                    <div class="table-bottom table-action-btn-gorup text-center">
                        <activate-subscription ></activate-subscription>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ActivateSubscription from "@/components/global/subscription/ActivateSubscription.vue";
    export default {
        name: "DashboardBilling",
        components: {
            ActivateSubscription
        },
        computed: {
            company() {
                return this.$store.state.userCompany;
            },
        },
        data() {
            return {
                invoices: []
            }
        },
        mounted() {

            this.getInvoices();
        },
        methods: {
            openInvoice(invoice) {
                window.open(invoice.attributes.attachment._url, '_blank');
            },
            payInvoice(invoice){
                window.open(invoice.attributes.revolutLink, '_blank');
            },
            getInvoices() {
                let self = this;
                this.$store.dispatch('getCompany').then(() => {
                    this.$store.dispatch('getInvoices').then(response => {
                        this.invoices = response;
                    })
                }).catch(function(e){
                    self.$checkTokenExp(e)
                });
            },
            toggleModal() {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardBillingForm', data: this.company})
            }
        }
    }
</script>

<style scoped>
    .table-top {
        padding-left: 0;
        padding-right: 0;
    }
    .company-billing-info {
        min-width: 420px;
    }

    .table-action-btn {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        color: var(--color-dark);
        border: 1px solid var(--color-dark);
        border-radius: 50%;
        text-decoration: none;
        /* transition: all 0.25s ease-in-out; */
        background: transparent;
        font-size: 10px;
    }

    .table-action-btn:hover,
    .table-action-btn:focus,
    .table-action-btn:active {
        color: var(--color-white);
        background: var(--color-dark);
    }
    .eco-content-scroll{
        padding: 10px;
    }
    .eco-content-space {
        background: #FFFFFF;
        padding: 25px;
    }
</style>
