import Parse from 'parse'

export const DesignVariantService = {
    getPrimaryClientDesign(context, companyId) {
        let designVariant = Parse.Object.extend('DesignVariant');
        let query = new Parse.Query(designVariant);
        let compPointer = {
            __type: 'Pointer',
            className: 'Company',
            objectId: companyId
        };
        query.equalTo('relatedCompany', compPointer);
        query.equalTo('designType', 'Primary');

        return new Promise((resolve, reject) => {
            query.first().then(response => {
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },

    addPrimaryDesign(context, payload) {
        let designVariant = Parse.Object.extend('DesignVariant');
        let newDesign = new designVariant();
        let compPointer = {
            __type: 'Pointer',
            className: 'Company',
            objectId: payload.compId
        };
        newDesign.set('relatedCompany', compPointer);
        newDesign.set('designType', 'Primary');

        let newDesignFile = new Parse.File(payload.designFile.name, payload.designFile);
        newDesign.set('attachment', newDesignFile);

        return new Promise((resolve, reject) => {
            newDesign.save().then(response => {
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },

    getClientDesigns(context, companyId) {
        let designVariant = Parse.Object.extend('DesignVariant');
        let query = new Parse.Query(designVariant);
        let compPointer = {
            __type: 'Pointer',
            className: 'Company',
            objectId: companyId
        };
        query.equalTo('relatedCompany', compPointer);
        query.equalTo('designType', 'Variant');
        query.descending('createdAt');

        return new Promise((resolve, reject) => {
            query.find().then(response => {
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    }
};
