import {UserService} from './UserService'
import {CompanyService} from './CompanyService'
import {OfficeAddressService} from './OfficeAddressService'
import {EmployeeService} from './EmployeeService'
import {ClientService} from './ClientsService'
import {InvoiceService} from './InvoiceService'
import {MonthlyStatsService} from './MonthlyStatsService'
import {NotesService} from "@/store/services/NotesService";
import {CardConfigService} from './CardConfigService'
import {globalActions} from './globalActions'
import {PricingService} from './PricingService'
import {PromoService} from "@/store/services/PromoService";
import {ProtectedDocsService} from "@/store/services/ProtectedDocsService";
import {DesignVariantService} from "@/store/services/DesignVariantService";

export const actions = {
    ...globalActions,
    ...UserService,
    ...CompanyService,
    ...OfficeAddressService,
    ...EmployeeService,
    ...ClientService,
    ...InvoiceService,
    ...CardConfigService,
    ...PricingService,
    ...PromoService,
    ...ProtectedDocsService,
    ...MonthlyStatsService,
    ...NotesService,
    ...DesignVariantService
};
