<template>
    <div class="eco-content-space h100 overflow-y-auto">
        <div class="white-box">
            <div class="table-top d-block">
                <b-row>
                    <b-col cols="12">
                        <div class="table-top-buttons">
                            <button-field @click="addNewClient()"
                                          icon="plus"
                                          color="primary">{{$ml.get('ADD_CLIENT')}}
                            </button-field>
                        </div>
                    </b-col>
                </b-row>

                <!--<div class="table-top-search">-->
                <!--{{$ml.get('Search')}}-->
                <!--</div>-->

                <b-row class="pt-2">
                    <b-col cols="2">
                        <b-form-group label="Search">
                            <b-form-input
                                v-model="searchString"
                                :placeholder="$ml.get('search_by_name_vat_website')"
                                class="font-small-2"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="2">
                        <b-form-group label="Filter by status">
                            <b-form-select
                                v-model="filterStatus"
                                :options="optionsStatus"
                                class="font-small-2"
                                placeholder="Filter by status">
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col cols="2">
                        <b-form-group label="Filter by promo">
                            <b-form-select
                                v-model="filterPromo"
                                :options="optionsPromos"
                                :disabled="loadingPromos"
                                class="font-small-2"
                                placeholder="Filter by promo">
                                <template #busy>
                                    <div class="d-flex align-items-center">
                                        <b-spinner small class="mr-2"></b-spinner>
                                        <small>Loading promos...</small>
                                    </div>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3" v-if="isCore">
                        <b-form-group label="Agency" class="bootstrapped-v-select filter-v-select">
                            <Select2
                                v-model="selectedClientOfId"
                                :options="generalSelectCompanies"
                                :placeholder="$ml.get('COMPANY')"
                                :settings="{
                    templateResult: companyPreview,
                    templateSelection: selectionPreview,
                    escapeMarkup: function(stuff){return stuff}
                }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-checkbox
                            v-model="showSuspended"
                            switch
                            size="md"
                            class="mr-2 mt-2 pt-1"
                            id="showSuspended"
                            name="showSuspended">
                            {{$ml.get('show_suspended_clients')}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </div>
            <div class="eco-content-scroll">
                <div  class="table-list-wrapper">
                    <b-table
                        :fields="tableColumns"
                        :items="tableRows"
                        :empty-html="emptyHtml"
                        class="font-small-2 table-vertical-align-middle table-list"
                        :class="loadingClientList ? 'qf-table-blur' : ''"
                    >
                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedRows[data.item.id]"
                                @change="onRowSelect(data.item.id, $event)"
                                :value="true"
                                :unchecked-value="false"
                                variant="secondary"
                                class="accent-secondary b-table-checkbox"
                            />
                        </template>


                        <template #cell(logo)="data">
                            <div class="hasCursor circular-logo" :class="isSuspended(data.item) ? 'opacity-50' : ''"  @click="goToClientCards(data.item)">
                                <img v-if="data.item.attributes.logo"
                                     :src="data.item.attributes.logo._url"/>
                            </div>
                        </template>

                        <template #cell(attributes.name)="data">
                            <div class="hasCursor td-elipsis-200" :title="data.item.attributes.name" :class="isSuspended(data.item) ? 'opacity-50' : ''"  @click="goToClientCards(data.item)">
                                {{data.item.attributes.name}}
                            </div>
                            <span class="font-small-1 td-elipsis-150 text-muted">{{data.item.attributes.billingName}}</span>
                        </template>

                        <template #cell(attributes.createdAt)="data">
                            {{data.item.attributes.createdAt | dateFormat}}
                        </template>

                        <template #cell(attributes.trialUntil)="data">
                            <div v-if="data.item.attributes.trialUntil">
                                <p
                                    class="mb-0"
                                    :class="trialExpired(data.item) ? 'text-danger' : 'text-success'">
                                    {{data.item.attributes.trialUntil | dateFormat}}
                                </p>
                                <span v-if="trialDaysRemaining(data.item) > 0" class="font-small-1 text-success">{{trialDaysRemaining(data.item)}} {{$ml.get('days_remaining')}}</span>
                                <span v-else class="font-small-1 text-danger">{{trialDaysRemaining(data.item)}} {{$ml.get('days_past_trial')}}</span>
                            </div>


                        </template>

                        <template #cell(attributes.status)="data">
                            <div class="d-flex flex-column">
                                <span :class="'text-'+computeStatus(data.item).color">
                                    <font-awesome-icon :icon="computeStatus(data.item).icon"/>
                                    {{computeStatus(data.item).text}}
                                    <sup class="font-small-1 text-primary cursor-pointer" @click="openAddNote(data.item)">
                                        <font-awesome-icon
                                            :icon="['fas', 'sticky-note']"
                                            v-if="data.item.attributes.latestNote"
                                            v-b-tooltip="data.item.attributes.latestNote.attributes.content"
                                        />
                                    </sup>
                                </span>
                                <span v-if="data.item.attributes.status === '04_active' &&
                                            data.item.attributes.subscribedOn"
                                       class="font-small-1 text-secondary"
                                >
                                   {{$ml.get('since')}} {{data.item.attributes.subscribedOn | dateFormat}}
                                </span>

                            </div>


                        </template>

                        <template #cell(hasUsers)="data">
                            <span class="td-elipsis" :class="data.item.attributes.relatedUsers.length > 0 ? 'text-secondary' : 'text-warning'">
                                {{data.item.attributes.relatedUsers.length > 0 ? 'Yes' : 'No'}}
                            </span>
                        </template>

                        <template #cell(promo)="data">
                            <span class="td-elipsis">
                                {{extractPromo(data.item)}}
                            </span>
                        </template>

                        <template #cell(attributes.currentCardCount)="data">
                            <div class="ellipsis text-center">{{calculateCards(data.item)}}</div>
                        </template>

                        <template #cell(attributes.billingEmail)="data">
                            <span @click="$copyToClipboard(data.item.attributes.billingEmail)">{{data.item.attributes.billingEmail}}</span>
                        </template>

                        <template #cell(actions)="data">
                            <div class="d-flex justify-content-center align-items-center">
                                <b-dropdown
                                    variant="link"
                                    toggle-class="p-0"
                                    no-caret
                                    right
                                >
                                    <template #button-content>
                                        <font-awesome-icon
                                            class="text-dark"
                                            :icon="['fas', 'ellipsis-h']" />
                                    </template>

                                    <div class="title-separator d-flex py-2">
                                        <span class="px-2 font-small-1 text-muted text-uppercase text-nowrap">Client settings</span>
                                        <span class="line"></span>
                                    </div>

                                    <b-dropdown-item
                                        class="px-0 low-padding-dropdown"
                                        @click="editClient(data.item.id)"
                                    >
                                        <div class="qf-icon-wrap">
                                            <font-awesome-icon
                                                class="text-primary mr-1"
                                                :icon="['fas', 'edit']" />
                                        </div>
                                        {{ $ml.get('tooltip_edit_client') }}
                                    </b-dropdown-item>


                                    <b-dropdown-item
                                        class="px-0 low-padding-dropdown"
                                        @click="editAgencySettings(data.item.id)"
                                        v-if="isAdmin"
                                    >
                                        <div class="qf-icon-wrap">
                                            <font-awesome-icon
                                                class="text-dark mr-1"
                                                :icon="['fas', 'drafting-compass']" />
                                        </div>
                                        {{ $ml.get('tables.actions.adminSettings') }}
                                    </b-dropdown-item>


                                    <b-dropdown-item
                                        class="px-0 low-padding-dropdown"
                                        @click="editPricing(data.item.id)"
                                        v-if="isAdmin"
                                    >
                                        <div class="qf-icon-wrap">
                                            <font-awesome-icon
                                                class="text-dark mr-1"
                                                :icon="['fas', 'dollar-sign']" />
                                        </div>
                                        {{ $ml.get('tooltip_edit_pricing') }}
                                    </b-dropdown-item>

                                    <b-dropdown-item
                                        class="px-0 low-padding-dropdown"
                                        @click="openAddNote(data.item)"
                                    >
                                        <div class="qf-icon-wrap">
                                            <font-awesome-icon
                                                class="text-info mr-1"
                                                :icon="['fas', 'sticky-note']" />
                                        </div>
                                        {{ $ml.get('tables.actions.notes') }}
                                    </b-dropdown-item>

                                    <div class="title-separator d-flex py-2">
                                        <span class="px-2 font-small-1 text-muted text-uppercase">Onboarding</span>
                                        <span class="line"></span>
                                    </div>

                                    <b-dropdown-item
                                        class="px-0 low-padding-dropdown"
                                        @click="openActivateTrial(data.item)"
                                    >
                                        <div class="qf-icon-wrap">
                                            <font-awesome-icon
                                                class="text-dark mr-1"
                                                :icon="['fas', 'calendar']" />
                                        </div>
                                        {{ $ml.get('tables.actions.edit_trial') }}
                                    </b-dropdown-item>


                                    <b-dropdown-item
                                        class="px-0 low-padding-dropdown"
                                        @click="openResendDesignModal(data.item)"
                                        :disabled="data.item.attributes.status !== '02_ready'"
                                    >
                                        <div class="qf-icon-wrap">
                                            <font-awesome-icon
                                                class="text-dark mr-1"
                                                :icon="['fas', 'sync']" />
                                        </div>
                                        {{ $ml.get('tables.actions.resendDesigns') }}
                                    </b-dropdown-item>


                                    <b-dropdown-item
                                        :disabled="data.item.attributes.relatedUsers.length !== 0"
                                        class="px-0 low-padding-dropdown"
                                        @click="openCreateUser(data.item)"
                                    >
                                        <div class="qf-icon-wrap">
                                            <font-awesome-icon
                                                class="text-secondary mr-1"
                                                :icon="['fas', 'user-plus']" />
                                        </div>
                                        {{ $ml.get('tables.actions.create_user') }}
                                    </b-dropdown-item>

                                    <b-dropdown-item
                                        class="px-0 low-padding-dropdown"
                                        @click="openTrialEndedModal(data.item)"
                                        :disabled="data.item.attributes.status !== '03_trial'"
                                    >
                                        <div class="qf-icon-wrap">
                                            <font-awesome-icon
                                                class="text-dark mr-1"
                                                :icon="['fas', 'hourglass-end']" />
                                        </div>
                                        {{ $ml.get('tables.actions.sendTrialExpired') }}
                                    </b-dropdown-item>

                                    <div class="title-separator d-flex py-2">
                                        <span class="px-2 font-small-1 text-muted text-uppercase text-nowrap">Links</span>
                                        <span class="line"></span>
                                    </div>

                                    <b-dropdown-item
                                        class="px-0 low-padding-dropdown"
                                        @click="openCalendlyModal(data.item)"
                                        :activated="isSuspended(data.item)"
                                    >
                                        <div class="qf-icon-wrap">
                                            <font-awesome-icon
                                                class="text-dark mr-1"
                                                :icon="['fas', 'paper-plane']" />
                                        </div>
                                        {{ $ml.get('tooltip_send_calendly') }}
                                    </b-dropdown-item>

                                    <b-dropdown-item
                                        class="px-0 low-padding-dropdown"
                                        @click="copySelfOnboardLink(data.item)"
                                    >
                                        <div class="qf-icon-wrap">
                                            <font-awesome-icon
                                                class="text-dark mr-1"
                                                :icon="['fas', 'link']" />
                                        </div>
                                        {{ $ml.get('tooltip_onboard_link') }}
                                    </b-dropdown-item>

                                    <div class="title-separator d-flex py-2">
                                        <span class="px-2 font-small-1 text-muted text-uppercase text-nowrap">Termination</span>
                                        <span class="line"></span>
                                    </div>
                                    <b-dropdown-item
                                        class="px-0 low-padding-dropdown"
                                        @click="suspendClient(data.item.id)"
                                        :activated="isSuspended(data.item)"
                                    >
                                        <div class="qf-icon-wrap">
                                        <font-awesome-icon
                                            class="text-dark mr-1"
                                            :icon="['fas', 'moon']" />
                                        </div>
                                        {{ suspendMesssage(data.item) }}
                                    </b-dropdown-item>


                                    <b-dropdown-item
                                        class="px-0 low-padding-dropdown"
                                        @click="deleteClient(data.item.id)"
                                        :disabled="forbidDelete(data.item)">
                                        <div class="qf-icon-wrap">
                                        <font-awesome-icon
                                            class="text-danger mr-1"
                                            :icon="['fas', 'trash-alt']" />
                                        </div>
                                        {{ $ml.get('tooltip_delete_client') }}
                                    </b-dropdown-item>

                                </b-dropdown>
                            </div>
                        </template>

                    </b-table>

                </div>

            </div>
            <div class="table-bottom">
                <div class="table-bottom-buttons-admin" v-if="false">
                    <button-field @click="bulkDelete"
                                  icon="trash-alt"
                                  color="error"
                                  :disabled="!selectedRowsTrue.length">
                        {{$ml.get('DELETE_SELECTED')}} ({{selectedRowsTrue.length}})
                    </button-field>
                </div>

                <div class="d-flex justify-content-between w-100 px-3">
                    <div class="table-result-per-page">
                        <b-form-select
                            v-model="pagination.perPage"
                            :options="perPageOptions"
                            @change="handlePerPageChange"
                            class="per-page-select">
                        </b-form-select>
                    </div>

                    <b-pagination
                        v-model="pagination.page"
                        :total-rows="pagination.total"
                        :per-page="pagination.perPage"
                        @change="handlePageChange"
                        class="mb-0"
                        first-number
                        last-number
                        align="center"
                        aria-controls="client-table">
                    </b-pagination>

                    <div class="total-items">
                        Total: {{ pagination.total }} items
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="resendDesignModal"
                 ref="resendDesignModal"
                 :title="$ml.get('modals.titles.resendDesign')"
                 :ok-variant="'secondary'"
                 :cancel-variant="'outline-primary'"
                 @ok="sendDesignReadyEmail"
                 @cancel="clearSendMailModals"
        >

                <b-row>
                    <b-col cols="6">
                        <b-form-checkbox v-model="resendSMS">Resend welcome SMS</b-form-checkbox>
                    </b-col>

                    <b-col cols="6">
                        <b-form-input v-model="resendPhone" :readonly="!resendSMS"></b-form-input>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group label="Additional message in email">
                            <b-form-textarea
                                v-model="resendDesignMessage"
                                placeholder="Enter a message to the client"
                            ></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>



        </b-modal>

        <b-modal id="sendTrialEndedModal"
                 ref="sendTrialEndedModal"
                 :title="$ml.get('modals.titles.sendTrialExpired')"
                 :ok-variant="'secondary'"
                 :ok-title="$ml.get('buttons.SEND')"
                 :cancel-variant="'outline-primary'"
                 :ok-disabled="!resendEmail || !canSendTrialEndedEmail"
                 @ok="sendTrialEndedEmail"
                 @cancel="clearSendMailModals"
        >

            <b-row>
                <b-col cols="12" v-if="canSendTrialEndedEmail">
                    <p class="font-small-3">This action will send a notification to the client that their trial had expired already.</p>
                    <p class="font-small-3">Enter an additional personalized message below which will be shown at the start of the e-mail.</p>
                </b-col>
                <b-col cols="12" v-else="">
                    <p class="font-small-3 text-danger">This client doesn't have a primary design assigned yet and therefore this e-mail can not be sent!</p>
                </b-col>

                <b-col>
                    <b-form-group label="Recipient e-mail">
                        <b-form-input v-model="resendEmail" placeholder=""></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group label="Additional message in email">
                        <b-form-textarea
                            v-model="resendDesignMessage"
                            rows="5"
                            placeholder="Enter a message to the client"
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>



        </b-modal>


        <b-modal
            id="calendlyModal"
            ref="calendlyModal"
            :title="$ml.get('send_calendly_invite')"
            :ok-title="$ml.get('send')"
            :ok-disabled="isCalendlyDisabled || loadingEmail"
            :ok-variant="'secondary'"
            :cancel-variant="'outline-primary'"
            @ok="sendCalendlyInvite"
        >
            <spinner-standard :show="loadingEmail" :text="$ml.get('sending')"></spinner-standard>
            <b-row class="mt-2">
                <b-col cols="12">
                    <b-form-group :label="$ml.get('recipient_email')">
                        <validation-observer ref="calendlyEmailObserver">
                            <validation-provider
                                #default="{ errors }" tag="div"
                                name="recipient e-mail"
                                rules="email|required"
                            >
                                <b-form-input v-model="calendlyEmail" placeholder="someone@acme.inc"
                                              :state="errors.length > 0 ? false:null"
                                              @keyup="errors.length > 0 ? isCalendlyDisabled = true : isCalendlyDisabled = false"
                                              required></b-form-input>
                                <div class="position-relative"><small class="text-danger qf-input-error">{{ errors[0] }}</small></div>
                            </validation-provider>
                        </validation-observer>
                    </b-form-group>
                </b-col>
            </b-row>

            <div v-if="calendlySentOn" class="row">
                <div  class="col-12 text-center">
                    <em class="font-small-1 text-warning">A calendly meeting e-mail had already been sent for this client on {{ niceDate(calendlySentOn)}}!</em>
                </div>
            </div>
        </b-modal>



        <b-modal
            id="activateTrial"
            ref="activateTrial"
            :title="$ml.get('tooltip_activate_trial')"
            :ok-title="$ml.get('activate')"
            :ok-variant="'secondary'"
            :cancel-variant="'outline-primary'"
            @ok="activateTrial">
            <b-row class="mt-2">
                <b-col cols="12">
                    <b-form-group :label="$ml.get('trial_start_date')">
                        <validation-observer ref="calendlyEmailObserver">
                            <validation-provider
                                #default="{ errors }" tag="div"
                                name="trial start date"
                                rules="required"
                            >
                                <b-form-datepicker
                                    v-model="modalTriallUntil"
                                    :state="errors.length > 0 ? false:null"
                                    required></b-form-datepicker>
                                <div class="position-relative"><small class="text-danger qf-input-error">{{ errors[0] }}</small></div>
                            </validation-provider>
                        </validation-observer>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>

    </div>
</template>

<script>
    import moment from "moment";
    import { VBToggle,VBModal } from 'bootstrap-vue';
    import { required, email} from '@/utils/validations/validations';
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import Parse from "parse";
    import Select2 from 'v-select2-component';
    import Api from "@/store/services/Api";
    export default {
        name: "DashboardClients",
        directives: {
            'b-toggle': VBToggle,
            'b-modal': VBModal
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            Select2
        },
        setup() {
            return {
                required,
                email,
            }
        },
        computed: {
            paginatedRows() {
                return this.$store.state.privateAgencyClients || [];
            },
            generalSelectCompanies() {
                let selectData = [];

                if (this.generalChoices) {
                    this.generalChoices.forEach(oneAgency => {
                        let oneLine = {
                            id: oneAgency.id,
                            text: oneAgency.attributes.name,
                            fullData: oneAgency.attributes,
                            companyObj: oneAgency,
                            isAgency: true
                        };
                        selectData.push(oneLine);
                    });
                }
                return selectData;

            },

            isCore(){
                if(this.$store.state.userCompany && this.$store.state.userCompany.attributes){
                    return this.$store.state.userCompany.attributes.core;
                }else {
                    return false;
                }
            },
            userCompany() {
                return this.$store.state.userCompany;
            },
            // selectedClientOfId(){
            //     return this.$store.state.agencyId;
            // },
            isAdmin() {
                if(this.$store.state.user && this.$store.state.user.attributes){
                    return this.$store.state.user.attributes.isAdmin;
                }else {
                    return false;
                }
            },
            tableRows() {
                let rows = this.$store.state.privateAgencyClients;
                if( this.$store.state.userCompany
                    && this.$store.state.userCompany.attributes.isAgency
                    && !this.$store.state.userCompany.attributes.core
                ){
                    const isUserCompanyPresent = rows.some(item => item.id === this.$store.state.userCompany.id);
                    if (!isUserCompanyPresent) {
                        rows.push(this.$store.state.userCompany);
                    }
                }

                // Add the hasUsers property to each company
                rows.forEach(company => {
                    company.hasUsers = Array.isArray(company.attributes?.relatedUsers) && company.attributes.relatedUsers.length > 0;
                });

                let suspended = rows.filter((item) => {
                    return item.attributes.suspended;
                });

                let active = rows.filter((item) => {
                    return !item.attributes.suspended;
                });

                return this.showSuspended ? suspended.concat(active) : active;
            },
            selectedRowsTrue() {
                let selected = [];
                for (const key in this.selectedRows) {
                    if (this.selectedRows[key]) {
                        selected.push(key);
                    }
                }
                return selected;
            }
        },
        data() {
            return {
                filterDefaults: {
                    status: null,
                    promo: null,
                    search: '',
                    page: 1,
                    perPage: 25,
                    showSuspended: false,
                    agencyId: null
                },



                canSendTrialEndedEmail: false,
                loadingPromos: false,
                filterPromo: null,
                optionsPromos: [
                    { value: null, text: 'All promos' }
                    // Will be populated dynamically
                ],

                resendCompany: {},
                resendDesignMessage: '',
                resendPhone: '',
                resendEmail: '',
                resendSMS: true,

                modalCompany: null,
                modalTriallUntil: null,
                calendlyId: null,
                calendlyEmail: '',
                calendlySentOn: null,
                isCalendlyDisabled:true,
                loadingEmail: false,
                loadingClientList: false,

                filterClientOf: 'all',
                generalChoices: [],
                selectedClientOfId: null,
                companyPlaceholder: require('../../assets/icon_bw.png'),

                filterStatus: null,
                optionsStatus: [
                    { value: null, text: 'All' },
                    { value: 'x_prio', text: 'High priority' },
                    { value: '01_new', text: 'New' },
                    { value: '02_ready', text: 'Ready to onboard' },
                    { value: '03_trial', text: 'Trial' },
                    { value: '04_active', text: 'Active' },
                    { value: '05_canceled', text: 'Canceled' },
                    { value: 'suspended', text: 'Suspended' },
                ],

                tableColumns: [
                    {
                        key: 'logo',
                        label: '',
                        thClass: 'tbl-pic',
                        tdClass:'td-avatar',
                        sortable: false
                    },
                    {
                        key: 'attributes.name',
                        label: this.$ml.get('COMPANY_NAME'),
                        thClass: 'font-bebas-neue font-small-4',
                        tdClass: 'td-elipsis-150',
                        sortable: true
                    },
                    {
                        key: 'attributes.status',
                        label: this.$ml.get('STATUS'),
                        thClass: 'font-bebas-neue font-small-4',
                        tdClass: 'td-elipsis-150',
                        sortable: true
                    },
                    {
                        key: 'hasUsers',
                        label: this.$ml.get('HAS_USERS'),
                        thClass: 'font-bebas-neue font-small-4',
                        tdClass: 'td-elipsis-150',
                        sortable: true
                    },
                    {
                        key: 'promo',
                        label: this.$ml.get('PROMO'),
                        thClass: 'font-bebas-neue font-small-4',
                        tdClass: 'td-elipsis-150',
                        sortable: false
                    },
                    {
                        key: 'attributes.createdAt',
                        label: this.$ml.get('CREATED_ON'),
                        thClass: 'font-bebas-neue font-small-4',
                        sortable: true
                    },
                    {
                        key: 'attributes.trialUntil',
                        label: this.$ml.get('TRIAL_UNTIL'),
                        thClass: 'font-bebas-neue font-small-4',
                        sortable: true
                    },
                    // {
                    //     key: 'attributes.responsible',
                    //     label: this.$ml.get('RESPONSIBLE'),
                    //     thClass: 'tbl-chk font-bebas-neue  font-small-4 td-elipsis-150',
                    //     sortable: true
                    // },
                    {
                        key: 'attributes.billingEmail',
                        label: this.$ml.get('BILLING_EMAIL'),
                        tdClass: 'td-elipsis-150 cursor-pointer',
                        thClass: 'tbl-chk font-bebas-neue  font-small-4 ',
                    },
                    {
                        key: 'attributes.currentCardCount',
                        label: this.$ml.get('TOTAL_CARDS'),
                        thClass: 'font-bebas-neue font-small-4 text-center',
                        sortable: true
                    },
                    {
                        key: 'actions',
                        label: this.$ml.get('ACTIONS'),
                        thClass: 'font-bebas-neue text-center font-small-4',
                        //tdClass: 'sticky-column',
                        sortable: false,
                        stickyColumn: true
                    }
                ],
                emptyHtml: '<div class="text-center font-small-4">No data available</div>',
                selectedAgency: null,

                perPageOptions: [
                    { value: 10, text: '10 per page' },
                    { value: 25, text: '25 per page' },
                    { value: 50, text: '50 per page' },
                    { value: 100, text: '100 per page' }
                ],
                perPageValue: 25,
                pagination: {
                    total: 0,
                    page: 1,
                    perPage: 25,
                    totalPages: 0
                },

                selectedRows: {},
                sharing: false,
                wizzardOpened: true,
                searchString: null,
                timeout: null,
                showSuspended: false,
                checkingAgencies: false,
            }
        },
        created() {
            // First restore filters from URL if present
            this.restoreFiltersFromURL();

            this.$store.dispatch('getCompany').then((userCompany) => {
                let agencyId = this.selectedClientOfId || userCompany.id;

                const determineAgencyId = () => {
                    if (userCompany.attributes.core) {
                        if (this.$route.params.agencyId) {
                            return Promise.resolve(this.$route.params.agencyId);
                        } else {
                            return Promise.resolve(this.$store.state.agencyId);
                        }
                    } else {
                        return Promise.resolve(agencyId);
                    }
                };

                determineAgencyId().then((finalAgencyId) => {
                    this.$store.commit('setAgencyId', finalAgencyId);
                    this.selectedClientOfId = finalAgencyId;
                    this.loadPromoOptions();
                    this.loadClientList();
                });

                this.getAllAgencies();
            });
        },


        methods: {
            // Modified updateURLParameters method
            updateURLParameters() {
                const query = {};

                // Copy existing query parameters that we don't manage
                Object.keys(this.$route.query).forEach(key => {
                    if (!['status', 'promo', 'search', 'page', 'perPage', 'showSuspended', 'agencyId'].includes(key)) {
                        query[key] = this.$route.query[key];
                    }
                });

                // Only add parameters that differ from defaults and aren't null/empty
                if (this.filterStatus) {
                    query.status = this.filterStatus;
                }

                // Explicitly check for non-null because null is a valid filter value meaning "All promos"
                if (this.filterPromo !== null) {
                    query.promo = this.filterPromo;
                }

                if (this.searchString && this.searchString.trim()) {
                    query.search = this.searchString;
                }

                if (this.pagination.page !== 1) {
                    query.page = this.pagination.page;
                }

                if (this.pagination.perPage !== this.filterDefaults.perPage) {
                    query.perPage = this.pagination.perPage;
                }

                if (this.showSuspended === true) {  // Explicit comparison to ensure boolean true
                    query.showSuspended = 'true';
                }

                if (this.selectedClientOfId && this.selectedClientOfId !== this.filterDefaults.agencyId) {
                    query.agencyId = this.selectedClientOfId;
                }

                // Replace URL without reloading the page
                this.$router.replace({ query }).catch(() => {});
            },

            // New method to restore filters from URL
            restoreFiltersFromURL() {
                const query = this.$route.query;

                // Restore each filter if present in URL
                if (query.status) {
                    this.filterStatus = query.status;
                }
                if (query.promo) {
                    this.filterPromo = query.promo;
                }
                if (query.search) {
                    this.searchString = query.search;
                }
                if (query.page) {
                    this.pagination.page = parseInt(query.page);
                }
                if (query.perPage) {
                    this.pagination.perPage = parseInt(query.perPage);
                }
                if (query.showSuspended) {
                    this.showSuspended = query.showSuspended === 'true';
                }
                if (query.agencyId) {
                    this.selectedClientOfId = query.agencyId;
                }

                // Update store with restored filters
                this.$store.commit('setClientFilters', {
                    status: this.filterStatus,
                    promo: this.filterPromo,
                    search: this.searchString,
                    agencyId: this.selectedClientOfId
                });
            },


            loadPromoOptions() {
                this.loadingPromos = true;

                // Use your existing PromoService through Vuex action
                this.$store.dispatch('getPromos', {
                    showExpired: true  // Include expired promos in the filter
                }).then(() => {
                    const promos = this.$store.state.promos;

                    this.optionsPromos = [
                        { value: null, text: 'All promos' },
                        ...promos.map(promo => ({
                            value: promo.id,
                            text: `${promo.attributes.code}${this.isPromoExpired(promo) ? ' (expired)' : ''}`
                        }))
                    ];
                }).finally(() => {
                    this.loadingPromos = false;
                });
            },

            isPromoExpired(promo) {
                if (promo.attributes.validUntil) {
                    return new Date(promo.attributes.validUntil) < new Date();
                }
                return false;
            },


            handlePageChange(page) {
                this.pagination.page = page;
                this.loadClientList();
            },

            handlePerPageChange(perPage) {
                this.pagination.perPage = perPage;
                this.pagination.page = 1; // Reset to first page when changing items per page
                this.loadClientList();
            },


            loadClientList() {
                this.loadingClientList = true;
                this.$store.dispatch('getClientsOfAgency', {
                    agencyId: this.selectedClientOfId,
                    status: this.filterStatus,
                    promo: this.filterPromo,
                    searchTerm: this.searchString,
                    page: this.pagination.page,
                    perPage: this.pagination.perPage
                }).then(paginationData => {
                    this.pagination = paginationData;
                    this.updateURLParameters(); // Update URL after successful load
                }).finally(() => {
                    this.loadingClientList = false;
                });
            },
            addNoteOnResend(companyId, phone){

                let payload = {
                    id: null,
                    date: new Date(),
                    content: 'Design was resent to the client via email',
                    channel: 'email',
                    author: this.$store.state.user.id
                }


                if(phone){
                    payload.content += ' and SMS';
                }

                this.$store.dispatch('saveNote', {companyId: companyId, ...payload}).then(() => {});
            },


            addNoteOnTrialExpEmail(companyId){
                let payload = {
                    id: null,
                    date: new Date(),
                    content: 'Client was notified that the trial period has ended',
                    channel: 'email',
                    author: this.$store.state.user.id
                }
                this.$store.dispatch('saveNote', {companyId: companyId, ...payload}).then(() => {});
            },


            clearSendMailModals(){
                this.canSendTrialEndedEmail = false;
                this.resendCompany = null;
                this.resendDesignMessage = '';
                this.resendPhone = '';
                this.resendEmail = '';
                this.resendSMS = true;
                this.$bvModal.hide('resendDesignModal');
                this.$bvModal.hide('sendTrialEndedModal');
            },
            openResendDesignModal(company){
                this.resendCompany = company;
                this.$store.commit('setGlobalLoader', true);

                this.$store.dispatch('getFirstCardForClient', company.id).then((card) => {
                    this.resendPhone = card.attributes.mobilePhone;
                    this.$bvModal.show('resendDesignModal');
                }).finally(()=>{
                    this.$store.commit('setGlobalLoader', false);
                });
            },

            openTrialEndedModal(company){
                this.resendCompany = company;
                this.resendDesignMessage = 'This is Manuel from Qfecard. We saw that your trial for our digital business card had expired and you did not activate your account. If you need more time to show it to your colleagues and to get to the right person within your organization, please let me know by email.';

                this.$store.dispatch('getPrimaryClientDesign', company.id).then((design) => {
                    if(design) {
                        this.canSendTrialEndedEmail = true;
                    }
                });

                this.$store.commit('setGlobalLoader', true);

                this.$store.dispatch('getFirstCardForClient', company.id).then((card) => {
                    this.resendEmail = card.attributes.email;
                    this.$bvModal.show('sendTrialEndedModal');
                }).finally(()=>{
                    this.$store.commit('setGlobalLoader', false);
                });

            },


            sendTrialEndedEmail() {
                let formData = new FormData();
                formData.append('action', 'sendTrialEnded');

                let payload = {
                    compId: this.resendCompany.id,  // Include the company ID in the payload
                    recipient: this.resendEmail,
                    additionalNote: this.resendDesignMessage,
                    senderId: this.$store.state.user.id,
                };

                formData.append('data', JSON.stringify(payload));
                this.$store.commit('setGlobalLoader', true);

                // Make the API call to send the email notification
                Api().post('helpers/api.php', formData)
                    .then(response => {
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification.email_sent_success'),
                        });

                        this.$store.dispatch('refreshCardCompany').then(()=>{
                            console.log("Refreshed")
                        });

                        this.addNoteOnTrialExpEmail(this.resendCompany.id)
                        //this.prepareCardData();
                        console.log('Trial Ended email sent successfully:', response);
                    })
                    .catch(error => {
                        console.error('Error re-sending trial ended email:', error);
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            text: error.message ? error.message : this.$ml.get('notification_error_general'),
                        });

                    }).finally(()=>{
                    this.clearSendMailModals();
                    this.$store.commit('setGlobalLoader', false);

                });

            },

            sendDesignReadyEmail() {
                // Prepare the payload for the API call
                let formData = new FormData();
                formData.append('action', 'sendDesignReadyEmail');

                let payload = {
                    compId: this.resendCompany.id,  // Include the company ID in the payload
                    additionalNote: this.resendDesignMessage,
                    senderId: this.$store.state.user.id,
                };

                if(this.resendSMS){
                    payload.phone = this.resendPhone;
                }

                formData.append('data', JSON.stringify(payload));
                this.$store.commit('setGlobalLoader', true);

                // Make the API call to send the email notification
                Api().post('helpers/api.php', formData)
                    .then(response => {
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_saved'),
                        });

                        this.$store.dispatch('refreshCardCompany').then(()=>{
                            console.log("Refreshed")
                        });

                        this.addNoteOnResend(this.resendCompany.id,  payload.phone)
                        //this.prepareCardData();
                        console.log('Design ready email sent successfully:', response);
                    })
                    .catch(error => {
                        console.error('Error re-sending design ready email:', error);
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            text: error.message ? error.message : this.$ml.get('notification_error_general'),
                        });

                    }).finally(()=>{
                    this.clearSendMailModals();
                    this.$store.commit('setGlobalLoader', false);

                });
            },
            openCreateUser(company) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardUserForm', data: {id: null, parentCompany: company}})
            },
            getAllAgencies() {
                if(this.isCore) {
                    let compQuery = new Parse.Query('Company');
                    compQuery.equalTo('isAgency', true);
                    compQuery.notEqualTo('core', true);
                    compQuery.descending('priority');

                    if(!this.showSuspended){
                        compQuery.notEqualTo('suspended', true);
                    }

                    compQuery.include('relatedCompanies');
                    compQuery.find().then((agencies) => {

                        // Sort the agencies array
                        const sortedAgencies = agencies.sort((a, b) => {
                            // Get priority values
                            const priorityA = a.get('priority') || 0;
                            const priorityB = b.get('priority') || 0;

                            // Compare priorities
                            if (priorityA !== priorityB) {
                                return priorityB - priorityA; // Descending order by priority
                            }

                            // If priorities are the same or undefined, sort alphabetically by name
                            const nameA = a.get('name') ? a.get('name').toLowerCase() : '';
                            const nameB = b.get('name') ? b.get('name').toLowerCase() : '';

                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                            return 0; // Names are equal
                        });

                        // Log sorted agencies
                        sortedAgencies.forEach(agency => {
                            let agencyObj = agency.attributes;
                            // Get and sort related companies (clients) alphabetically by name
                            let sortedClients = (agencyObj.relatedCompanies || []).sort((clientA, clientB) => {
                                const nameA = clientA.get('name') ? clientA.get('name').toLowerCase() : '';
                                const nameB = clientB.get('name') ? clientB.get('name').toLowerCase() : '';

                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }
                                return 0; // Names are equal
                            });

                            agency.sortedClients = sortedClients;


                        });
                        this.generalChoices = sortedAgencies;

                        if(!this.selectedClientOfId) {
                           // this.selectedClientOfId = sortedAgencies[0].id;
                        }
                    });

                }
                else {
                    let agencyObj = this.userCompany.attributes;
                    let sortedClients = (agencyObj.relatedCompanies || []).sort((clientA, clientB) => {
                        const nameA = clientA.get('name') ? clientA.get('name').toLowerCase() : '';
                        const nameB = clientB.get('name') ? clientB.get('name').toLowerCase() : '';

                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0; // Names are equal
                    });

                    this.userCompany.sortedClients = sortedClients;
                    this.generalChoices = [this.userCompany];
                }
            },
            selectionPreview(selection) {
                let markup = '';

                if (selection.children && selection.children.length > 0) {
                    // It's an agency with children
                    let agency = selection.companyObj.attributes;
                    if (!agency.logo) {
                        agency.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    markup += `<div class="agency selection">`;
                    markup +=   `<span class="profile-avatar">`;
                    markup +=       `<img class="comp-logo" src="` + agency.logo._url + `" />`;
                    markup +=   `</span>`;
                    markup +=   `<h4>` + selection.text + `</h4>`;
                    markup += `</div>`;
                } else if (selection.fullData) {
                    let item = selection.fullData;

                    if (!item.logo) {
                        item.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    markup += selection.fullData.suspended ? `<div class="company selection suspended">` : `<div class="company selection">`;

                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + item.logo._url + `" />`;
                    markup += `</span>`;
                    if(item.clientOf && !selection.fullData.isAgency){
                        markup += `<div class="item-name">`;
                        markup += `<h4>` + item.name + `</h4>`;
                        markup += `<div class="client-of-name">`+item.clientOf.attributes.name+`</div>`;
                        markup += `</div>`;
                    }else {
                        markup += `<h4>` + item.name + `</h4>`;
                    }
                    markup += `</div>`;
                }

                return markup;
            },
            companyPreview(choice) {
                let markup = '';

                if (choice.isAgency) {
                    let agency = choice.fullData;
                    if (!agency.logo) {
                        agency.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    markup += `<div class="agency" data-agency-id="${choice.id}">`;
                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + agency.logo._url + `" />`;
                    markup += `</span>`;
                    markup += `<h4 class="d-inline">` + choice.text + `</h4>`;
                    markup += `</div>`;
                } else if (choice.fullData) {
                    let item = choice.fullData;

                    if (!item.logo) {
                        item.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    if (item.suspended) {
                        markup = `<div class="company suspended">`;
                    } else {
                        markup += choice.isOwnCompany ? `<div class="company own">` : `<div class="company">`;
                    }

                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + item.logo._url + `" />`;
                    markup += `</span>`;

                    if(item.clientOf && !item.isAgency){
                        markup += `<div class="item-name">`;
                        markup += `<h4>` + item.name + `</h4>`;
                        markup += `<div class="client-of-name">`+item.clientOf.attributes.name+`</div>`;
                        markup += `</div>`;
                    }else {
                        markup += `<h4>` + item.name + `</h4>`;
                    }
                    markup += `</div>`;
                }

                return markup;
            },
            activateTrial(){
                this.modalCompany.set('trialUntil', new Date(this.modalTriallUntil));
                this.modalCompany.save().then(() => {
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_saved'),
                        });
                        this.$store.dispatch('getClientsOfAgency', {
                            status : this.filterStatus,
                            agencyId: this.selectedClientOfId
                        });
                    }).catch(() => {
                        this.loadingEmail = false;
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            text: this.$ml.get('notification_error_general'),
                        });
                    });
            },
            niceDate(date) {
                return moment(date).format('DD/MM/Y');
            },
            sendCalendlyInvite(){
                let self = this;
                self.loadingEmail = true;
                this.$store.dispatch('sendCalendlyEmail', {
                    id: this.calendlyId,
                    calendlyEmail: this.calendlyEmail}).then(() => {
                    self.$notify({
                        group: 'notification',
                        type: 'success',
                        text: self.$ml.get('notification_success_calendly'),
                    });
                    self.calendlyId = null;
                    self.loadingEmail = false;
                    self.$refs.calendlyModal.hide();
                }).catch(() => {
                    self.loadingEmail = false;
                    self.$notify({
                        group: 'notification',
                        type: 'error',
                        text: self.$ml.get('notification_error_calendly'),
                    });
                })
            },
            openCalendlyModal(item) {
                this.inviteModalData = item;
                this.calendlyId = item.id;
                this.calendlySentOn = item.attributes.calendlySentOn;
                if(item.attributes.billingEmail) {
                    this.calendlyEmail = item.attributes.billingEmail;
                } else if(item.attributes.email){
                    this.calendlyEmail = item.attributes.email;
                } else {
                    this.calendlyEmail = '';
                }
                this.$bvModal.show('calendlyModal');
            },
            openActivateTrial(item){
                this.modalCompany = item;
                this.modalTriallUntil = item.attributes.trialUntil ? item.attributes.trialUntil : moment().add(14, 'days');
                this.$bvModal.show('activateTrial');
            },

            trialDaysRemaining(item){
                let trialUntil = new Date(item.attributes.trialUntil);
                let remainingTrial = moment(trialUntil).diff(moment(), 'days');
                return remainingTrial;
            },
            showClients(item){
                this.selectedAgency = item;
                this.$store.dispatch('getAgencyClients', {clientOf:item})
            },
            calculateCards(item) {
                return item.attributes.currentCardCount ? item.attributes.currentCardCount : 0;
            },
            calculateAgencyCards(item){
                let total = 0;
                if(item.attributes.relatedCompanies){
                    item.attributes.relatedCompanies.forEach((client) => {
                        if(client.attributes.currentCardCount){
                            total += client.attributes.currentCardCount;
                        }
                    })
                }
                return total;
            },
            computeStatus(item){
                let output = {
                    text: '',
                    color: '',
                    icon: 'check'
                };

                switch (item.attributes.status) {
                    case 'x_prio':
                        output.text = 'High Priority';
                        output.color = 'danger';
                        output.icon = 'star';
                        break;

                    case '01_new':
                        output.text = 'New';
                        output.color = 'blue';
                        output.icon = 'star';
                        break;
                    case '02_ready':
                        output.text = 'Ready to onboard';
                        output.color = 'dark';
                        output.icon = 'calendar';
                        break;
                    case '03_trial':
                        output.text = 'Trial';
                        output.color = 'warning';
                        output.icon = 'clock';
                        break;
                    case '04_active':
                        output.text = 'Active';
                        output.color = 'secondary';
                        output.icon = 'check';
                        break;
                    case '05_canceled':
                        output.text = 'Canceled';
                        output.color = 'danger';
                        output.icon = 'times';
                        break;
                    default:
                        if(this.isSuspended(item)) {
                            output.text = 'Suspended';
                            output.color = 'muted';
                            output.icon = 'moon';
                        }else {
                            output.text = 'Unknown';
                            output.color = 'muted';
                            output.icon = 'question';
                        }

                        break;
                }

                return output;
            },

            trialExpired(item){
                let trialUntil = new Date(item.attributes.trialUntil);
                let today = new Date();
                return trialUntil < today;
            },

            extractPromo(item) {
                if (item.attributes.usedPromo
                    && item.attributes.usedPromo.attributes
                    && item.attributes.usedPromo.attributes.code
                ) {
                    return item.attributes.usedPromo.attributes.code;
                } else {
                    return '';
                }
            },

            doSearch(searchString){
                this.$store.dispatch('getClientsOfAgency', {searchTerm : searchString, agencyId: this.selectedClientOfId});
            },
            suspendedRow(item){
                if(this.isSuspended(item)){
                    return 'suspended';
                }else{
                    return '';
                }
            },
            copySelfOnboardLink(item){

                let invitationLink = window.location.protocol + '//' + window.location.host
                    +'/addme/'+item.id+'/'+item.attributes.frameSecret;

                navigator.clipboard.writeText(invitationLink);

                this.$notify({
                    group: 'notification',
                    type: 'success',
                    text: this.$ml.get('notification_onboard_link_copied')
                });

            },
            suspendMesssage(item){
                if(this.isSuspended(item)){
                    return this.$ml.get('RESTORE_CLIENT');
                }else {
                    return this.$ml.get('SUSPEND_CLIENT');
                }
            },
            isSuspended(item){
                return item.attributes.suspended;
            },
            suspendClient(id){
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientSuspend', data: {ids: [id]}})
            },
            forbidDelete(item){
                return item.attributes.isAgency;
            },
            forbidAgencyDelete(item){
                return item.attributes.relatedCompanies.length > 0;
            },
            toggleModal(componentName) {
                this.$store.commit('toggleDashboardModal', {name: componentName})
            },
            addNewClient(){
                this.$router.push({
                        name: 'signup',
                        params: {agencyId: this.$store.state.agencyId},
                        query: {nm: 1}
                    }
                );
            },
            editAgencySettings(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardAgencySettingsForm', data: {id: id, dataSet: 'agencyClients'}})
            },
            editPricing(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'PricingForm', data: {id: id, dataSet: 'agencyClients'}})
            },

            editClient(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientsForm', data: {id: id, dataSet: 'agencyClients'}})
                this.wizzardOpened = true;
            },
            openAddNote(item){
                this.$store.commit('toggleDashboardModal', {name: 'NoteForm', data: {id: item.id, dataSet: 'agencyClients'}})
            },
            deleteClient(id, isAgency = false) {
                // DashboardEmployeeCardsDelete
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientsDelete', data: {ids: [id], isAgency: isAgency, dataSet: 'agencyClients'}})
            },
            bulkDelete() {
                this.$store.commit('toggleDashboardModal', {
                    name: 'DashboardClientsDelete',
                    data: {ids: this.selectedRowsTrue}
                })
            },
            goToClientCards(clientCompany) {
                let self = this;
                this.$store.dispatch('changeImpersonation', clientCompany).then(function(response){
                    if(response.attributes.impersonating){
                        self.$notify({
                            group: 'notification',
                            type: 'success',
                            text: "Switching to managing "+clientCompany.attributes.name+"...",
                        });
                        self.$store.commit('setCardCompany',clientCompany);
                        setTimeout(function(){
                            self.$router.push({name: 'home'});
                        }, 1500);
                    }

                });


            },
        },
        watch : {
            showSuspended() {
                // Just update the URL without reloading data
                this.updateURLParameters();
            },
            filterPromo(newPromo) {
                this.$store.commit('setClientFilters', {promo: newPromo});
                this.pagination.page = 1; // Reset to first page
                this.loadClientList();
            },
            selectedClientOfId(newId) {
                this.$store.commit('setAgencyId', newId);
                this.$store.commit('setClientFilters', {agencyId: newId});
                this.pagination.page = 1; // Reset to first page
                this.loadClientList();
            },
            searchString(newSearch) {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.$store.commit('setClientFilters', {search: newSearch});
                    this.pagination.page = 1; // Reset to first page
                    this.loadClientList();
                }, 300);
            },
            filterStatus(newStatus) {
                this.$store.commit('setClientFilters', {status: newStatus});
                this.pagination.page = 1; // Reset to first page
                this.loadClientList();
            }
        }
    }
</script>

<style>
.title-separator {
    align-items: center;
}
.line {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}
</style>


<style scoped>
    .select2-container{
        width: 100% !important;
        padding: 20px 0;
    }
    .selection {
        padding-top: 5px;
    }
    .select2-container--default .select2-selection--single{
        min-height: 50px;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
        top: 36px;
    }

    .per-page-select {
        width: 110px;
    }
    .hasCursor {
        cursor: pointer;
    }
    .suspended {
        opacity: 0.5;
    }

    .text-success {
        color: var(--color-secondary);
    }

    .text-danger {
        color: var(--color-error);
    }

    .selected-agency-preview {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .company-logo {
        max-height: 16px;
        max-width: 40px;
    }

</style>
